import Head from 'next/head'

export default function Custom404() {
  return(
    <div className="container">
      <Head>
        <title>David &amp; Chloë - 404</title>
        <link rel="shortcut icon" href="static/favicon.ico" />
      </Head>

      <main>
        <h1 className="title">
          💔
        </h1>

        <p className="description">
          Oh dear, this page doesn&apos;t exist!
        </p>
      </main>

      <style jsx >{`
        main {
          display: flex;
          padding: 0 1rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        main {
          padding: 5rem 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .title {
          margin: 0;
          line-height: 1;
          font-size: 6rem;
        }

        .title,
        .description {
          text-align: center;
        }

        .description {
          line-height: 1.5;
          font-size: 2.5rem;
        }
      `}</style>

      <style jsx global>{`
        :root {
          font-size: 62.5%;
        }

        html,
        body {
          padding: 0;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
            sans-serif;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>
    </div>
  )
}
